import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const Navigation = (props) => {
  return (
    <>
      <nav className="d-none d-lg-block">
        <ul className="nav nav-pills" id="mainNav">
          <li
            className={
              "navlink  " +
              (props.current === "Home"
                ? "bg-light text-primary rounded"
                : "bg-primary text-light") +
              ""
            }
          >
            <NavLink
              className={
                "navlink rounded text-3  " +
                (props.current === "Home"
                  ? "bg-light text-primary rounded"
                  : "bg-primary text-light") +
                ""
              }
              to="/"
            >
              Home
            </NavLink>
          </li>  
          <li
            className={
              "navlink  " +
              (props.current === "QSTags"
                ? "bg-light text-primary rounded"
                : "bg-primary text-light") +
              ""
            }
          >
            <NavLink
              className={
                "navlink rounded text-3  " +
                (props.current === "QSTags"
                  ? "bg-light text-primary rounded"
                  : "bg-primary text-light") +
                ""
              }
              to="/tags"
            >
              QS Tags
            </NavLink>
          </li>          
          {/*<li
            className={
              "dropdown " +
              (props.current === "Reports"
                ? "bg-light text-primary rounded"
                : "bg-primary text-light") +
              ""
            }
          >
            <NavLink
              className={
                "nav-link dropdown-toggle text-3  " +
                (props.current === "Reports"
                  ? "bg-light text-primary rounded"
                  : "bg-primary text-light") +
                ""
              }
            >
              Reports
            </NavLink>
            <ul class="dropdown-menu">
              <li
                className={
                  (props.dropdown === "FVS2024"
                    ? " text-primary"
                    : " text-tertiary") + ""
                }
              >
                <NavLink
                  className={
                    "nav-link text-2 " +
                    (props.dropdown === "FVS2024"
                      ? " text-primary font-weight-bold"
                      : " text-primary") +
                    ""
                  }
                  to="/reports-fvs2024"
                >
                  FVS2024
                </NavLink>
              </li>
            </ul>
          </li>*/}

        </ul>
      </nav>

      <nav className="d-block d-lg-none border-0">
        <ul className="nav" id="mainNav">
          <li
            className={
              "border-0 mt-1 p-2 " +
              (props.current === "Home"
                ? "bg-primary text-light"
                : "bg-light text-primary") +
              ""
            }
          >
            <NavLink
              className={
                "navlink text-3  " +
                (props.current === "Home" ? " text-light" : " text-primary") +
                ""
              }
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li
            className={
              "border-0 mt-1 p-2 " +
              (props.current === "QSTags"
                ? "bg-primary text-light"
                : "bg-light text-primary") +
              ""
            }
          >
            <NavLink
              className={
                "navlink text-3  " +
                (props.current === "QSTags" ? " text-light" : " text-primary") +
                ""
              }
              to="/tags"
            >
              QS Tags
            </NavLink>
          </li>
          
        </ul>
      </nav>
    </>
  );
};

export default Navigation;
