import Moment from "react-moment";
import Help from "./Help";

const InfoWrapper = (props) => {
  
    return (    
    <>
    
    <div
    className={
        "rounded p-1 mb-1 " +
        (props.inputtype === "HEADER" ? " bg-header font-weight-bold text-primary text-uppercase" : "bg-light bg-qs-" + props.level + "")}
        >

        <div className="row">
        <div className={"col-12  col-sm-4 pt-0 pb-0"}>                    
            <div
                className="float-start"
                dangerouslySetInnerHTML={{
                    __html: props?.title ? props.title : "",
                }}
            />
        </div>              
        <div className={"col-12  col-sm-6 pt-0 pb-0"}>                               
            {props.defaultvalues.length === 0 && 
            <>
                {(props.inputtype === "radioOpp" || props.inputtype === "radio") && 
                    <>True or False</>
                }
            </>
            }
            {typeof props.defaultvalues !== null && 
                <>

                    {props.defaultvalues.map((list,index) => {                                                
                        return (
                            <><span className="text-primary" >{list.value}</span> - {list.valueText}<br /></>
                            
                        )                        
                    })}
                </>                
            }
        </div>   
        <div className="col-12 col-sm-2 pt-0 pb-0 text-end">

{typeof props.type === "undefined" && 
<>
    {props.value}
</>
}

{props.type === "list" && 
<>
    {props.value.length === 0 && 
    <>-</>
    }

    {props.value.map((list,index) => {
        return (
            <span key={index}>{list.keyname}<br /></span>
        )
    })}
</>
}
 {props.type === "date" && 
<>
    <Moment format="DD/MM/YYYY" >{props.value}</Moment>
</>
}

{typeof props.tag !== "undefined" && 
   <>&nbsp;&nbsp;<span className={"tag bg-" + props.tagstyle + " text-light rounded mt-1"}>{props.tag}</span></>
}
&nbsp;
</div> 

                
              
                       
        </div>
    </div>
    {typeof props.relation !== "undefined" &&
    <p className="text-primary text-end text-2 mb-2 mt-0" >       
        {typeof props.relation !== "undefined" ? "" + props.value + " will only appear if " + props.parentquestionid + " value is " : ""}                

        {(props.relation === true || props.relation === false || props.relation === "true" || props.relation === "false") && 
        <>
           {props.relation === true || props.relation === "true" ? "True" : ""}
           {props.relation === false || props.relation === "false" ? "False" : ""}
        </>
        }

        {(props.relation !== true && props.relation !== false && props.relation !== "true" && props.relation !== "false") && 
        <>
            {props.relation}
        </>
        }

    </p>
    }
    </>
    );
  };
  
  export default InfoWrapper;
  