import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

//Import Components
import useToken from "../App/useToken";
import Error from "../FormElements/Error";
import LoginModal from "../Login/LoginModal";

const UserInfo = (props) => {
  //Generic
  const { token, setToken } = useToken();
  const [info, setInfo] = useState([]);
  //On Screen Messages
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(true);
  //Errors
  const [error, setError] = useState(false);
  const [errorcode, setErrorCode] = useState(0);
  const [errormessage, setErrorMessage] = useState("");
  //Login
  const [loginmodal, setLoginModal] = useState(false);
  //Component Specific
  const [userinfo, setUserInfo] = useState([]);
  const [userfullname, setUserFullname] = useState("");
  const [useremail, setUserEmail] = useState("");
  const [useragentref, setAgentRef] = useState("");
  const [useragentname, setAgentName] = useState("");

  useEffect(() => {
    (async () => {
      const apidata = JSON.stringify({});

      let code = 0;
      try {
        const response = await fetch("//testwebapi.iprism.co.uk/users/me", {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const jsonResponse = await response.json();
          const listFromJson = jsonResponse;
          setUserFullname((prev) => {
            return [listFromJson.fullname];
          });
          setUserEmail((prev) => {
            return [listFromJson.email];
          });
          setAgentRef((prev) => {
            return [listFromJson.agentref];
          });
          setAgentName((prev) => {
            return [listFromJson.agentname];
          });
          setLoading(false);
          setLoaded(true);
          return listFromJson;
        } else {
          console.log("ERROR");
          const jsonResponse = await response.json();
          console.log("IN THIS SECTION " + jsonResponse.detail + "");
          setErrorMessage(jsonResponse.detail);
          code = response.status;
          throw new Error(code);
        }
      } catch (error) {
        if (code === 401) {
          setLoginModal(true);
        } else {
          setError(true);
          setErrorCode(code);
        }
      }
    })();
  }, []);

  const refreshPage = () => {
    console.log("REFRESHING PAGE");
    window.location.reload(false);
  };

  const logout = () => {
    console.log("LOGGING OUT");
    localStorage.removeItem("token");
    console.log("LOGGED OUT");
    refreshPage();
  };

  const handleRightSidebar = () => {
    alert("THIS SHOULD OPEN THE SIDEBAR");
  };

  return (
    <>
      {loginmodal === true && <LoginModal setToken={setToken} />}
      {error === true && (
        <Error
          code={errorcode}
          endpoint="WebsiteContentList"
          component="news"
        />
      )}
      {error === false && (
        <>
          <div id="userbox" className="userbox">
            <a href="#" data-bs-toggle="dropdown">
              <div className="profile-info" style={{ width: "200px" }}>
                <span className="name text-secondary">{userfullname}</span>
                <span className="role text-tertiary">{useragentname}</span>
              </div>

              <i className="fa custom-caret"></i>
            </a>

            <div className="dropdown-menu">
              <ul className="list-unstyled mb-2">
                <li className="divider"></li>
                {/*<li>
                  <NavLink to="/profile" role="menuitem" tabIndex="-1">
                    <i className="bx bx-user-circle"></i> My Profile
                  </NavLink>
                </li>*/}
                <li>
                  <a
                    onClick={logout}
                    role="menuitem"
                    tabIndex="-1"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bx bx-power-off"></i> Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <span className="separator"></span>
        </>
      )}
    </>
  );
};

export default UserInfo;
