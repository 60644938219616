import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Header Components
import Header from "../Header/Header";
import useToken from "../App/useToken";

//Elements
import PleaseWait from "../FormElements/PleaseWait";
import SectionWrapper from "../FormElements/SectionWrapper";
import SectionHeader from "../FormElements/SectionHeader";
import PageHeader from "../FormElements/PageHeader";

import InfoWrapper from "../FormElements/InfoWrapper";

import Description from "../FormElements/Description";
import ReadOnly from "../FormElements/ReadOnly";
import Error from "../FormElements/Error";

//Question Set Elements
import Title from "./Elements/Title";
import Radio from "./Elements/Radio";
import RadioStatement from "./Elements/RadioStatement";
import Input from "./Elements/Input";
import DataList from "./Elements/DataList";
import Select from "./Elements/Select";
import TableWrapper from "./Elements/TableWrapper";
import TableItem from "./Elements/TableItems";
import TableInsert from "./Elements/TableInsert";
import Textarea from "./Elements/TextArea";

import LookUpAddress from "./Elements/LookUpAddress";
import List from "./Elements/List";
import ListRelation from "./Elements/ListRelation";
import ListNoAction from "./Elements/ListNoAction";
import ListMultiple from "./Elements/ListMultiple";

const QSTags = (props) => {
   
  const search = window.location.search;
  const params = new URLSearchParams(search);

  //const [propref, setPropRef] = useState(params.get("propref"));
  //const [cob, setCOB] = useState(params.get("cob"));

/*  if (params.get("propref") !== "")
  {
    setPropRef(props.propref)
    setCOB(props.cob)
  }

  */

  //Generic Components
  const { token, setToken } = useToken();
  const [loginmodal, setLoginModal] = useState(false);
  const [error, setError] = useState(false);
  const [errorcode, setErrorCode] = useState(0);
  const [errormessage, setErrorMessage] = useState("");
  const [errorvalidate, setErrorValidate] = useState(false);
  const [errorvalidatecount, setErrorValidateCount] = useState(0);
  const [errorvalidatemessage, setErrorValidateMessage] = useState("");

  // Modal
  const [modal, setModal] = useState(false);
  const [modalpropref, setModalPropRef] = useState(false);
  const [modaltype, setModalType] = useState(false);
  const [modalinfo, setModalInfo] = useState([]);
  const [modalloading, setModalLoading] = useState(true);
  const [modalloaded, setModalLoaded] = useState(false);
  const [modaldata, setModalData] = useState([]);

  //Quotation Info
  const [cob, setCOB] = useState("");
  const [cobname, setCOBName] = useState("");

   //Question Set
   const [questions, setQuestions] = useState([]);
   const [referencedata, setReferenceData] = useState([]);
   const [save, setSave] = useState(false);
   const [saved, setSaved] = useState(false);
   const [savedmessage, setSavedMessage] = useState("");
   const [savederror, setSavedError] = useState("");
   
 

  //List Values
  const [listvalue1, setListValue1] = useState("")
  const [listvalue2, setListValue2] = useState("")
  const [listvalue3, setListValue3] = useState("")
  const [listvalue4, setListValue4] = useState("")
  const [listvalue5, setListValue5] = useState("")
  const [listtext1, setListText1] = useState("")
  const [listtext2, setListText2] = useState("")
  const [listtext3, setListText3] = useState("")
  const [listtext4, setListText4] = useState("")
  const [listtext5, setListText5] = useState("")

  //On Screen Messages
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(true);

  const [surveycode, setSurveyCode] = useState("");

  {/*
  useEffect(() => {
    (async () => {
      let code = 0;
      let detail = "";
      const apidata = JSON.stringify({
        reference: "0",
        cob: "" + cob + "",
        pageno: "",
      });
      console.log(apidata);
      try {
        const response = await fetch("//devwebapi.iprism.co.uk/QS/MWI/Load", {
          method: "POST",
          body: apidata,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        console.log("IN THE RERSPONSE")
        if (response.ok) {
          const jsonResponse = await response.json();
          const originalquestions = jsonResponse.Data[0]
          const qsQuestions = jsonResponse.Data[0].question_data;
          const qsRefData = jsonResponse.Data[0].reference_data;
          const errorFromJson = jsonResponse.Error;
          if (errorFromJson !== "") {
            throw new Error(666, errorFromJson);
          }
          console.log("THIS IS THE ORIGINAL ARRAY",originalquestions);
          
          setReferenceData({"reference_data":qsRefData});

          setQuestions((prev) => {
            return [...qsQuestions];
          });

          setLoginModal(false);
          setLoading(false);
          setLoaded(true);
          console.log("THESE ARE THE QUESTIONS");
          console.log(qsQuestions);

          console.log("THIS THE REF DATA");
          console.log(qsRefData);

       //   return listFromJson;
        } else {
          console.log(response);
          code = response.status;
          console.log("line 68 " + code + "");
          throw new Error(code);
        }
      } catch (error) {
        setLoading(false);
        if (code === 401) {
          setLoginModal(true);
        } else {
          setError(true);
          setErrorCode(code);
        }
      }
    })();
  }, []);

 */}


 const handleCOBSelect = async (e) => {
  setError(false)  
  setErrorCode("");
  const selectedcob = e.target.value
  const selectedcobnarrative = e.target.getAttribute("narrative")
  setCOB(selectedcob)
  setCOBName(selectedcobnarrative)
  console.log("COBNAME",selectedcobnarrative)
  setLoading(true);
  setLoaded(false);
  const newQuery = JSON.stringify({
    reference: "0",
    cob: "" + selectedcob + "",
    pageno: "",
  });
  let code = 0;

  console.log("NEW QUERY", newQuery)
  try {
    const response = await fetch("//devwebapi.iprism.co.uk/QS/MWI/Load", {
      method: "POST",
      body: newQuery,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      const originalquestions = jsonResponse.Data[0]
      const qsQuestions = jsonResponse.Data[0].question_data;
      const qsRefData = jsonResponse.Data[0].reference_data;
      const errorFromJson = jsonResponse.Error;
      if (errorFromJson !== "") {
        throw new Error(666, errorFromJson);
      }
      console.log("THIS IS THE ORIGINAL ARRAY",originalquestions);
      
      setReferenceData({"reference_data":qsRefData});

      setQuestions((prev) => {
        return [...qsQuestions];
      });

      setLoginModal(false);
      setLoading(false);
      setLoaded(true);
      console.log("THESE ARE THE QUESTIONS");
      console.log(qsQuestions);

      console.log("THIS THE REF DATA");
      console.log(qsRefData);

   //   return listFromJson;
    } else {
      console.log(response);
      setLoaded(true)
      code = response.status;
      console.log("line 68 " + code + "");
      throw new Error(code);
      
    }
  } catch (error) {
    setLoading(false);
    if (code === 401) {
      setLoginModal(true);
    } else {
      setError(true);
      setErrorCode(code);
    }
    }
  }


  return (
    <>
      <div>
        <section className="body">
          <Header current="QSTags" />
          
          <div className="row">
          
          <div className="col-12 p-4 mt-0 mt-md-5 pt-2">       

<div className="row" >
                {loading && 
                
             <div className="col-12 col-md-12 p-3 pt-4">
                <PleaseWait />     
                </div>
                }


            {loaded && 
              <div className="col-12 col-md-12 p-3">
                <SectionHeader title="Question Set Tags" />
                <div className="row mt-0" >
                  <div className="col-12 col-md-4" >
                    <select onChange={handleCOBSelect} value={cob} className="mt-3" >
                      <option value="" ></option>
                      <optgroup label="Product Question Sets">Product Question Sets</optgroup>
                      <option value="MWI" narrative="Midwives" >&nbsp;&nbsp;Midwives</option>
                      <option value="BIR" narrative="Midwives - Birth Declaration" >&nbsp;&nbsp;Midwives - Birth Declaration</option>          
                      <optgroup label="Surveys">Surveys</optgroup>
                      <option value="FVS2024" narrative="Fair Value Survey 2024" >&nbsp;&nbsp;FVS2024</option>
                    </select>
                  </div>
                  <div className="col-4 text-end" >
                  
                  </div>
                </div>
              </div>
            }

      {loaded === true && cob !== "" && (
        
        <div className="col-12 col-md-12 p-3">
            {cobname}

            {questions.length === 0 && (
              <>This {props.cob} question set is not currently accessible</>
            )}
            <br />
          
            {questions.length !== 0 && (
              <div className="d-none">Current QS Page Length = {questions.length}</div>
            )}
          
            <PageHeader title={cob} />

            {questions.map((page, index) => {
              return (
                <>
                  <SectionHeader title={page.pagetitle}  />
                  
                      {page.questions.map((q, index) => {
                          return (
                                <>
                                
                                  <InfoWrapper title={q.type === "HEADER" ? q.title : q.body} value={q.id} inputtype={q.inputtype} level={1}  defaultvalues={q.defaultvalues} />
                                
                                    {q.childquestions !== null &&
                                      q.childquestions !== "" &&
                                      q.childquestions.map((cq) => {
                                        return (
                                          <>
                                            <InfoWrapper title={cq.type === "HEADER" ? cq.title : cq.body} value={cq.id} parentquestionid={q.id}  defaultvalues={cq.defaultvalues} relation={cq.relation} inputtype={cq.inputtype}  level={2} />
                                            {cq.childquestions !== null &&
                                              cq.childquestions !== "" &&
                                              cq.childquestions.map((ccq) => {
                                                return (
                                                  <>
                                                  
                                                    <InfoWrapper title={ccq.body} value={ccq.id} parentquestionid={cq.id} grandparentquestionid={q.id} defaultvalues={ccq.defaultvalues} relation={ccq.relation} inputtype={ccq.inputtype}  level={3} />
                                                  
                                                    {ccq.childquestions !== null &&
                                                      ccq.childquestions !== "" &&
                                                      ccq.childquestions.map((cccq) => {
                                                        return (
                                                          <>
                                                          
                                                            <InfoWrapper title={cccq.body} value={cccq.id} parentquestionid={ccq.id} defaultvalues={cccq.defaultvalues} grandparentquestionid={cq.id} greatgrandparentquestionid={q.id} relation={cccq.relation} inputtype={cccq.inputtype}  level={4} />
                                                          
                                                          </>
                                                        )
                                                      })}

                                                  </>
                                                )
                                              })}
                                          </>
                                        )
                                      })}
                                
                                </>                           
                          );
                        })}                  
                </>
              );
            })}         
          </div>
      )}</div>
             </div>   
            </div>
        </section>
      </div>
    </>
  );
  
};

export default QSTags;
