import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { NavLink } from "react-router-dom";

//import Navigation from '../Navigation/Navigation';
import Home from "../Home/Home";
import QSTags from "../QuestionSets/QSTags";
import FVS2024 from "../Reports/FVS2024";

import Login from "../Login/Login";
import useToken from "./useToken";

const App = () => {
  const { token, setToken } = useToken();

  if (!token) {
    return <Login setToken={setToken} />;
  }

  return (
    <Router>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/reports-fvs2024" element={<FVS2024 />} />
        <Route path="/tags" element={<QSTags />} />        

        {/*<Route path="/nest" element={<Nest />} />*/}
      </Routes>
    </Router>
  );
};

export default App;
