import { useState } from "react";
import { Tooltip } from 'react-tooltip'

const Help = (props) => {

    return (
        <div className="tooltip-container mr-4 float-start">
            
            {props.type === "text" && 
                <>
                    &nbsp;&nbsp;
                    {props.text} 
                    &nbsp;&nbsp;
                <a data-tooltip-id={"tooltip" + props.qid +""} data-tooltip-html={props.helptext} className="mr-2 " style={{color:"#777"}}>
                   <i class="fas fa fa-question icon text-1 font-weight-bold pointer" style={{backgroundColor:"#80BCA6", color:"#fff", padding:"2px 4px 2px 4px", borderRadius: "50%"}} ></i>
                </a>&nbsp;
                </>
            }

            {props.type !== "text" && 
                <>
                &nbsp;
                <a data-tooltip-id={"tooltip" + props.qid +""} data-tooltip-html={props.helptext} className="font-weight-bold mr-2 ">
                    <i class="fas fa fa-question icon text-2 font-weight-bold pointer" style={{backgroundColor:"#80BCA6", padding:"5px 8px 5px 8px", borderRadius: "50%"}} ></i>
                </a>&nbsp;
                </>
            }

            <Tooltip id={"tooltip" + props.qid +""} width="300px"  opacity={1.0} place={"bottom"} data-tooltip-html={"true"}  className="tooltip-iprism rounded" />
        </div>
    )
}

export default Help;