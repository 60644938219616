import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";

import iprismlogo from "../Header/logo-white.png";
import iprismlogo1 from "../Header/logo.png";

const Logos = (props) => {
  return (
    <>
      {props.styling != "popup" && (
        <div
          className="logo-container border-0 bg-primary "
          style={{ border: "none ", width: "30%" }}
        >
          <NavLink to="/" className="logo" style={{ border: "none" }}>
            <img
              src={iprismlogo}
              height="35"
              alt="iprism Underwriter Manager"
              className=" d-md-block d-none"
            />
            <img
              src={iprismlogo1}
              height="45"
              alt="iprism Underwriter Manager"
              className=" d-md-none d-block mt-1"
            />
          </NavLink>
        </div>
      )}
    </>
  );
};

export default Logos;
