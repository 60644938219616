import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";


const Error = (props) => {
  return (
    <section
      className="card mt-0  bg-light"
      style={{ border: "medium solid #e9c7cd" }}
    >
      <div className="card-body bg-light ">
        <center>
          <div className="card-header-icon bg-light m-0 p-0 mb-4">
            <i className="fas fa-exclamation-triangle text-danger text-8"></i>
          </div>
          <h3 className="mt-0 font-weight-semibold mt-0 text-center">
            {props.code === 404 && <>API Endpoint Error</>}
            {props.code === 422 && <>Unprocessable Entity</>}
            {props.code === 500 && <>Internal Server Error</>}
          </h3>
          <p className="text-center text-danger mb-2">
            {props.code === 404 && (
              <>
                The <em>/{props.endpoint}</em> endpoint in{" "}
                <em>{props.component}.js</em> is currently unavailable.
              </>
            )}
            {props.code === 422 && (
              <>
                We have encountered a problem, please refresh this page. If this
                problem persists please contact us.
              </>
            )}
            {props.code === 500 && (
              <>
                We have encountered a problem displaying {props.info} due to an
                issue with the {props.endpoint} endpoint. Please refresh this
                page. If this problem persists please contact us.
                <br />
                <br />
                Endpoint Response: {props.message}
              </>
            )}
            The functionality is in development
          </p>
        </center>
      </div>
    </section>
  );
};

export default Error;
