import React, { useState } from "react";
import PropTypes from "prop-types";
import reCAPTCHA from "react-google-recaptcha";

import "./Login.css";
import iprismlogo from "../Header/logo-white.png";
import iprismlogo1 from "../Header/logo.png";

import PageHeader from "../Header/PageHeader";

const errormessage = "";
const loginerror = false;

async function loginUser(credentials) {
  var details = {
    username: credentials.username,
    password: credentials.password,
    scope: "iprism_upper",
    grant_type: "",
  };

  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  return fetch("//devwebapi.iprism.co.uk/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  }).then((data) => data.json());
}

export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [recaptcha, setRecaptcha] = useState(false);

  const [captchaRef, setcaptchaRef] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await loginUser({
      username,
      password,
    });
    //console.log(token)

    console.log("result >>", result);
    if (!result.access_token) {
      const loginerror = true;
      const errormessage = result.detail;
      document.getElementById("loginerror").classList.remove("d-none");
      document.getElementById("loginerror").classList.add("d-block");
      document.getElementById("loginerrormessage").innerHTML = errormessage;
      //setRecaptcha(true);
    } else {
      console.log(result);
      setToken(result);
      //setToken(token);
    }
  };

  return (
    <div>
      <section className="body border-0">
        <header className="header border-0 bg-primary">
          <div className="logo-container">
            <a href="/" className="logo">
              <img
                src={iprismlogo}
                height="35"
                alt="iprism Underwriting"
                className=" d-md-block d-none"
              />
              <img
                src={iprismlogo1}
                height="45"
                alt="iprism Underwriting"
                className=" d-md-none d-block mt-1"
              />
            </a>
          </div>
          <div className="float-end text-light text-6 pt-3">
            COMPLIANCE&nbsp;&nbsp;
          </div>
        </header>

        <div className="inner-wrapper ">
          <div className="row mt-5">
            <div className="col-10 offset-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4 col-xl-2 offset-xl-5 mt-5 mt-md-0">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col">
                    <label className="form-label text-color-dark text-3">
                      Email address <span className="text-color-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg text-2"
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="form-group col">
                    <label className="form-label text-color-dark text-3">
                      Password <span className="text-color-danger">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control form-control-lg text-2"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="form-group col">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary w-100"
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div className="row justify-content-between mt-3 mb-2 d-none ">
                  <div className="form-group col">
                    <a
                      href=""
                      className="btn btn-tertiary text-primary w-100 btn-sm"
                    >
                      Forgotten Your Password?
                    </a>
                  </div>
                </div>
                <div className="d-none">
                  <span className="d-block d-sm-none float-start">XS</span>
                  <span className="d-none d-sm-block d-md-none float-start">
                    SM
                  </span>
                  <span className="d-none d-md-block d-lg-none float-start">
                    MD
                  </span>
                  <span className="d-none d-lg-block d-xl-none float-start">
                    LG
                  </span>
                  <span className="d-none d-xl-block float-start">XL</span>
                </div>
              </form>

              <div
                className="alert alert-danger alert-dismissible mb-4 text-2 mr-0 pr-0 d-none"
                role="alert"
                id="loginerror"
              >
                <div className="row mr-0 pr-0">
                  <div className="col-12">
                    <strong>
                      <i className="fas fa-exclamation-triangle"></i> Login
                      Error:{" "}
                    </strong>{" "}
                    <span id="loginerrormessage"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
