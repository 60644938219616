import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const tokenBearer = localStorage.getItem('bearer');    
    const userToken = JSON.parse(tokenString);    
    const userBearer = JSON.parse(tokenBearer);    
    return userToken;
  };

  

  const [token, setToken] = useState(getToken());

  //console.log(token)
  
  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken.access_token));
    localStorage.setItem('bearer', JSON.stringify(userToken.token_type));
    setToken(userToken.access_token);
  };
  return {    
    setToken: saveToken,    
    token    
  }
}
