// ./components/SectionmWrapper.jsx

const SectionWrapper = (props) => {
  const sectionStyle = {
    border: "1px solid #ccc",
  };

  if (props?.variant === "light") sectionStyle.backgroundColor = "#fff";

  return (
    <>
      <div className="rounded p-2 pt-0 pb-0 mb-2 border-1" style={sectionStyle}>
        <div className="list-results p-1 pt-0 pb-0">{props?.children}</div>
      </div>
    </>
  );
};

export default SectionWrapper;

//<legend>{props?.title}:</legend>{" "}
