import React from "react";

import Title from "../FormElements/Title";
import Description from "../FormElements/Description";

import SVG, { Props as SVGProps } from "react-inlinesvg";

const svgSource = `<svg id="logo" version="1.1"  viewBox="0 0 645 365" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
    <metadata>
    <rdf:RDF>
    <cc:Work rdf:about="">
    <dc:format>image/svg+xml</dc:format>
    <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
    <dc:title/>
    </cc:Work>
    </rdf:RDF>
    </metadata>
    <g>
    <path  className="loaderlogo-inner"  d="m225.54 240.46 0.0916-73.166s-0.45803-14.665 18.596-2.7896c19.237 10.68 31.838 17.723 47.523 26.894 13.21 7.7237 24.161 9.8907 7.5788 19.19-6.9958 3.8887-55.102 30.988-55.102 30.988s-15.94 10.999-18.688-1.1158z" style="fill:none;stroke-width:3;stroke:#3d6690"/>
    <path  className="loaderlogo-middle"  d="m218.4 250.48 0.11487-91.752s-0.57439-18.39 23.32-3.4982c24.124 13.393 39.926 22.225 59.595 33.725 16.565 9.6857 30.299 12.403 9.5039 24.064-8.7729 4.8765-69.099 38.859-69.099 38.859s-19.989 13.793-23.435-1.3993z" style="fill:none;stroke-width:3;stroke:#9a697d"/>
    <path className="loaderlogo-outer" d="m210.71 260.82 0.14074-112.41s-0.70372-22.531 28.571-4.2858c29.556 16.409 48.917 27.23 73.014 41.32 20.296 11.867 37.122 15.196 11.644 29.483-10.748 5.9745-84.658 47.61-84.658 47.61s-24.49 16.899-28.712-1.7144z" style="fill:none;stroke-width:2.9733;stroke:#bea1ad"/>
    
    <path  className="loaderlogo-inner"  d="m365.54 240.46 0.0916-73.166s-0.45803-14.665 18.596-2.7896c19.237 10.68 31.838 17.723 47.523 26.894 13.21 7.7237 24.161 9.8907 7.5788 19.19-6.9958 3.8887-55.102 30.988-55.102 30.988s-15.94 10.999-18.688-1.1158z" style="fill:none;stroke-width:3;stroke:#3d6690"/>
    <path  className="loaderlogo-middle"  d="m358.4 250.48 0.11487-91.752s-0.57439-18.39 23.32-3.4982c24.124 13.393 39.926 22.225 59.595 33.725 16.565 9.6857 30.299 12.403 9.5039 24.064-8.7729 4.8765-69.099 38.859-69.099 38.859s-19.989 13.793-23.435-1.3993z" style="fill:none;stroke-width:3;stroke:#9a697d"/>
    <path className="loaderlogo-outer" d="m350.71 260.82 0.14074-112.41s-0.70372-22.531 28.571-4.2858c29.556 16.409 48.917 27.23 73.014 41.32 20.296 11.867 37.122 15.196 11.644 29.483-10.748 5.9745-84.658 47.61-84.658 47.61s-24.49 16.899-28.712-1.7144z" style="fill:none;stroke-width:2.9733;stroke:#bea1ad"/>
    </g>
</svg>`;

const PleaseWait = (props) => {
  //Notice PacsalCase name
  return (
    <div className="loaderlogo-move bg-light rounded pt-3">
      <div className="row">
        <div
          className="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4"
          style={{ marginTop: "-50px" }}
        >
          <SVG src={svgSource} />
        </div>
      </div>
    </div>
  );
};

export default PleaseWait;
