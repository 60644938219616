import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "search",
  searchType: "",
  searchTerm: "",
};

export const searchSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    saveSearchInfo: (state, action) => {
      state.searchType = action.payload.searchType;
      state.searchTerm = action.payload.searchTerm;
    },
  },
});

export const { saveSearchInfo } = searchSlice.actions;

export default searchSlice.reducer;
