// ./components/Button.jsx
import { useState } from "react";

const SectionHeader = (props) => {
  return (
    <div className="p-1 bg-primary rounded text-light text-uppercase text-3 mt-1 mb-1 font-weight-bold" >
        {props.title}                            

        {props.includeToggle && 
          <>
          <div className="float-end p-2 pt-0" >
          {props.value &&           
            <i class="fa-solid fa-caret-up float-end text-6"></i>
          }

          {!props.value &&           
            <i class="fa-solid fa-caret-down float-end text-6"></i>
          }
          </div>

          </>
        }
    </div>


  );
};

export default SectionHeader;
