import React, { useState, useEffect } from "react";

const ErrorMessage = (props) => {

    return (
 <div className="row" >
              <div className="col-12 col-lg-12 text-center pt-5" > 
                <i className="fas fa-exclamation-triangle text-primary"  style={{fontSize:"80px"}}></i>
                <p className="text-primary mt-5 text-uppercase" style={{fontSize:"40px"}}>{props.title}</p>                

                <p className="text-4 mt-5 text-uppercase">Sorry, we can't process your request at this time. Please try again.</p>
                <p className="text-4 text-uppercase" >{props.message}{typeof props.propref !== "undefined" ? ": " + props.propref + "" : "" }</p>

              </div>
            </div>

    )
    
}

    export default  ErrorMessage;