// ./components/Button.jsx
import { useState } from "react";

const PageHeader = (props) => {
  return (
    <div className="rounded p-0 pb-2 text-primary text-uppercase text-5 mt-1 mb-1 font-weight-bold">
        {props.title}                            
    </div>


  );
};

export default PageHeader;
