import React, { useState } from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import useToken from "../App/useToken";
import iprismlogo from "../Header/logo.png";

async function loginUser(credentials) {
  console.log("IN loginUser");
  var details = {
    username: credentials.username,
    password: credentials.password,
    scope: "iprism_upper",
    grant_type: "",
  };

  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  return fetch("//devwebapi.iprism.co.uk/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  }).then((data) => data.json());
}

function LoginModal({ setToken }, props) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);
  const [loginok, setLoginOK] = useState(false);
  const [errormessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await loginUser({
      username,
      password,
    });

    console.log("result >>", result);
    if (!result.access_token) {
      setError(true);
      setErrorMessage(result.detail);
    } else {
      setLoginOK(true);
      refreshPage();
      setError(false);
      setErrorMessage("");
      console.log(result);
      setToken(result);
    }
  };

  const refreshPage = () => {
    const reloadCount = sessionStorage.getItem("reloadCount");
    if (reloadCount < 2) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloadCount");
    }
  };

  return (
    <div>
      <div className="modal-login-container" id="modal-login-container">
        <div className="modallogin" id="modallogin">
          <img
            src={iprismlogo}
            alt="iprism Underwriter Manager"
            className="w-25 mb-3"
          />
          {loginok === false && (
            <div id="divLoginForm" className="d-block">
              <div className="text-3 text-primary font-weight-bold text-uppercase text-left mt-0 pt-0 mb-2 pb-0">
                Your CMS session has timed out
              </div>
              <p className="mt-2 mb-2">[Time out message to appear in here]</p>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col">
                    <label className="form-label text-color-dark text-3 mb-1 pb-0">
                      Email address <span className="text-color-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg text-2"
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="form-group col">
                    <label className="form-label text-color-dark text-3 mb-1 pb-0">
                      Password <span className="text-color-danger">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control form-control-lg text-2 mb-2"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mb-0 mt-2 pb-0">
                  <div className="form-group col">
                    <button
                      type="submit"
                      className="btn btn-primary w-100 btn-lg"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}

          {error === true && (
            <div className="alert alert-danger mb-2 text-2" role="alert">
              <div className="row">
                <div className="col-12">
                  <strong>
                    <i className="fas fa-exclamation-triangle"></i> Error:
                  </strong>{" "}
                  {errormessage}
                </div>
              </div>
            </div>
          )}
          {loginok === true && (
            <div>
              <div className="alert alert-success mb-2 text-2" role="alert">
                <div className="row">
                  <div className="col-12">
                    <strong>
                      <i className="fas fa-exclamation-triangle"></i> Success:
                    </strong>{" "}
                    Login Successful - Please Wait
                  </div>
                </div>
              </div>
              {/*}
              <button
                type="button"
                className="btn btn-primary w-100 btn-lg"
                onClick={refreshPage}
              >
                Continue
          </button>*/}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

LoginModal.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default LoginModal;
