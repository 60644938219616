import React, { useState, useEffect, useRef } from "react";
import useToken from "../App/useToken";
import Moment from "react-moment";

import { useDownloadExcel } from 'react-export-table-to-excel';
// Header Components
import Navigation from "../Navigation/Navigation";
import UserInfo from "../Header/UserInfo";
import Logos from "../Header/Logos";
import Notifications from "../Header/Notifications";
//Page Components

// Generic Elements
import TableWrapper from "../FormElements/TableWrapper";
import LoginModal from "../Login/LoginModal";
import Header from "../Header/Header";
import PageHeader from "../FormElements/PageHeader";
import SectionHeader from "../FormElements/SectionHeader";
import SectionWrapper from "../FormElements/SectionWrapper";
import ReadOnly from "../FormElements/ReadOnly";
import Title from "../FormElements/Title";
import PanelWrapper from "../FormElements/PanelWrapper";
import PleaseWait from "../FormElements/PleaseWait";
import Code from "../FormElements/Code";
import Button from "../FormElements/Button";
import Description from "../FormElements/Description";
import ErrorMessage from "../Error/ErrorMessage";

//Page Values

const Home = () => {
  // Login Values
  const [info, setInfo] = useState([]);
  const { token, setToken } = useToken();
  const [loginmodal, setLoginModal] = useState(false);
  // Loading & Error Values
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [loadeddelay, setLoadedDelay] = useState(false);
  

  const [error, setError] = useState(false);
  const [errorcode, setErrorCode] = useState(0);
  const [errormessage, setErrorMessage] = useState(null);

  //const tableRef = useRef(null);
  const tableRef = useRef(null);

  useEffect(() => {
    (async () => {
      let code = 0;
      //Populate COBS

      const apidata = JSON.stringify({        
        cob: "FVS2024", 
        surveyid: "FVS2024",
        
      });


      console.log("QUESTION SET LOAD", apidata);
      try {
        //const response = await fetch("https://devwebapi.iprism.co.uk/Survey/Report", {
        const response = await fetch("https://ipuapi.iprism.co.uk/Survey/Report", {
          method: "POST",
          body: apidata,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token + ""
          },
        });
        if (response.ok) {
          const jsonResponse = await response.json();
          const originalquestions = jsonResponse.Data;
          const epresponsedetail = jsonResponse.detail;   
          setInfo((prev) => {
            return [...originalquestions];
          });         
          return originalquestions;

         
        } else {
          code = response.status;
          throw new Error(response.status);
        }
      } catch (error) {
        setErrorMessage(error.message || 'An error occurred');  // Extract the error message        

        if (code === 401) {
          setLoginModal(true);
        } else {
          setError(true);
          setErrorCode(code);
        }
      }      
    })().then(function() { 
      setLoading(false);
      setLoaded(true);
      handleFinally()
    });
  }, []);


  const handleFinally = () => {
    setTimeout(() => {      
      setLoadedDelay(true)  
      console.log("WE HAVE FINISHED THE DELAY NOW SHOW THE BUTTON")
    }, 10000); // 5 seconds
  };

  
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'SurveyResponses',
    sheet: 'FVS2024'
})


  return (
    <>
      {loginmodal === true && <LoginModal setToken={setToken} />}

      <div>
        <section className="body" style={{overflow:"hidden"}}>
          <Header current="Compliance Home" />
              <div className="row">
              <div className="col-12 p-4 mt-0 mt-md-5 pt-2">      

<div className="row" >
                {loading && 
                
                <div className="col-12 col-md-12 p-3 pt-4">
                <PleaseWait />     
                </div>
                }



                {error === true && 
                        <>
                        <ErrorMessage title="Survey Export Unavailable" message={errormessage} />
                        
                        </>                  }


                {loaded && error !== true && 
              <>
             <div className="col-12 col-md-4 p-3">
              <SectionHeader title="Fair Value Survey 2024 Responses" />
              
              <SectionWrapper >
                <ReadOnly title="Total Responses" value={info.length-1} /> 
              </SectionWrapper >

              {loadeddelay && info.length > 0 && 
                <>      
                <button className="btn btn-primary mt-2 mb-2 btn-xs" onClick={onDownload}>Export To Excel</button>       
                </>
              }

              {!loadeddelay && info.length > 0 && 
                <>      
                Please wait whilst we prepare your export
                </>
              }

              </div>
              <div className="col-12 col-md-12 p-3">

                 <div className="w-100 mb-0">
      <div className="table-responsive" style={{ width: "100%", marginTop:"2000px" }}>
        
          <table width="100%" className="p-3 rounded border-1" ref={tableRef}>



                    {info.map((q, index) => {
                    return (
                      <>
                      {typeof q.base_questions === "object" &&
                        <thead>
                          <tr className="font-weight-bold text-light bg-secondary" style={{backgroundColor:"#3D6690", color:"#fff", fontWeight:"bold"}}  >      
                            <td></td>            
                            <td></td>         
                            {q.base_questions.map((page, pagesindex) => {
                              return (
                            
                                <>
                                {page.questions.map((question,index) => {
                                return (
                                  <>
                                  <td className="font-weight-bold p-1">
                                    
                                    <strong>{question.id}</strong>
                                  </td>

                                  {question.childquestions.map((childquestion,index) => {
                                return (
                                  <>
                                  <td className="font-weight-bold p-1">
                                    <strong>{childquestion.id}</strong>
                                  </td>
                              

                                  {childquestion.childquestions.map((grandchildquestion,index) => {
                                  return (
                                    <>
                                    <td className="font-weight-bold p-1">
                                      <strong>{grandchildquestion.id}</strong>
                                    </td>

                                        {grandchildquestion.childquestions.map((greatgrandchildquestion,index) => {
                                      return (
                                        <>
                                        <td className="font-weight-bold p-1">
                                          <strong>{greatgrandchildquestion.id}</strong>
                                        </td>

                                        
                                        </>
                                      )
                                    })}

                                  </>
                                )
                              })}

                                  </>
                                )
                              })}
                                  </>
                                )})}               
                                </>

                              )
                            })}
                          </tr>

                          <tr className="font-weight-bold text-light bg-primary" style={{backgroundColor:"#3D6690", color:"#fff", fontWeight:"bold"}}  >      
                            <td>Agency Ref</td>            
                            <td>Agency Name</td>         
                            {q.base_questions.map((page, pagesindex) => {
                              return (
                            
                                <>
                                {page.questions.map((question,index) => {
                                return (
                                  <>
                                  <td className="font-weight-bold p-1">
                                    
                                    <strong>{question.body}</strong>
                                  </td>

                                  {question.childquestions.map((childquestion,index) => {
                                return (
                                  <>
                                  <td className="font-weight-bold p-1">
                                    <strong>{childquestion.body}</strong>
                                  </td>
                              

                                  {childquestion.childquestions.map((grandchildquestion,index) => {
                                  return (
                                    <>
                                    <td className="font-weight-bold p-1">
                                      <strong>{grandchildquestion.body}</strong>
                                    </td>

                                        {grandchildquestion.childquestions.map((greatgrandchildquestion,index) => {
                                      return (
                                        <>
                                        <td className="font-weight-bold p-1">
                                          <strong>{greatgrandchildquestion.body}</strong>
                                        </td>

                                        
                                        </>
                                      )
                                    })}

                                  </>
                                )
                              })}

                                  </>
                                )
                              })}
                                  </>
                                )})}               
                                </>

                              )
                            })}
                          </tr>

                        </thead>
                      }

                      {typeof q.base_questions !== "object" &&
                        <tbody>
                          <tr className="" >      
                            <td>{q.agentref}</td>            
                            <td>{q.agentname}</td>            
                            {q.question_data.map((page,index) => {
                            return (
                              <>
                              {page.questions.map((question,index) => {
                              return (
                                <>
                                <td>
                                  {typeof question.value === "undefined" && 
                                  <></>
                                  }
                                  {typeof question.value === "object" && 
                                  <>
                                    {question.value.map((listvalue, list) => {
                                      return (
                                        <>
                                        {list > 0 && " / " }
                                        {listvalue.label}
                                        </>
                                      )
                                    })}
                                  </>
                                  }
                                  {typeof question.value === "boolean" && 
                                    <>
                                      {question.value ? "Yes" : "No"}
                                    </>
                                  }
                                  {typeof question.value === "string" && 
                                    <>
                                      {question.value}
                                    </>
                                  }

                                  </td>

                                {question.childquestions.map((childquestion,index) => {
                              return (
                                <>
                                <td className="font-weight-bold p-1">
                                {typeof childquestion.value === "undefined" && 
                                  <></>
                                  }
                                  {typeof childquestion.value === "object" && 
                                  <>
                                    {childquestion.value.map((listvalue, list) => {
                                      return (
                                        <>
                                        {list > 0 && " / " }
                                        {listvalue.label}
                                        </>
                                      )
                                    })}
                                  </>
                                  }
                                  {typeof childquestion.value === "boolean" && 
                                    <>
                                      {childquestion.value ? "Yes" : "No"}
                                    </>
                                  }
                                  {typeof childquestion.value === "string" && 
                                    <>
                                      {childquestion.value}
                                    </>
                                  }
                                </td>
                            

                                {childquestion.childquestions.map((grandchildquestion,index) => {
                                return (
                                  <>
                                  <td className="font-weight-bold p-1">
                                {typeof grandchildquestion.value === "undefined" && 
                                  <></>
                                  }
                                  {typeof grandchildquestion.value === "object" && 
                                  <>
                                    {grandchildquestion.value.map((listvalue, list) => {
                                      return (
                                        <>
                                        {list > 0 && " / " }
                                        {listvalue.label}
                                        </>
                                      )
                                    })}
                                  </>
                                  }
                                  {typeof grandchildquestion.value === "boolean" && 
                                    <>
                                      {grandchildquestion.value ? "Yes" : "No"}
                                    </>
                                  }
                                  {typeof grandchildquestion.value === "string" && 
                                    <>
                                      {grandchildquestion.value}
                                    </>
                                  }
                                </td>

                                    {grandchildquestion.childquestions.map((greatgrandchildquestion,index) => {
                                      return (
                                        <>
                                          <td className="font-weight-bold p-1">
                                            {typeof greatgrandchildquestion.value === "undefined" && 
                                              <></>
                                            }
                                            {typeof greatgrandchildquestion.value === "object" && 
                                            <>
                                              {greatgrandchildquestion.value.map((listvalue, list) => {
                                                return (
                                                  <>
                                                  {list > 0 && " / " }
                                                  {listvalue.label}
                                                  </>
                                                )
                                              })}
                                            </>
                                            }
                                            {typeof greatgrandchildquestion.value === "boolean" && 
                                              <>
                                                {greatgrandchildquestion.value ? "Yes" : "No"}
                                              </>
                                            }
                                            {typeof greatgrandchildquestion.value === "string" && 
                                              <>
                                                {greatgrandchildquestion.value}
                                              </>
                                            }
                                          </td>                                      
                                        </>
                                      )
                                    })}

                                </>
                              )
                            })}

                                </>
                              )
                            })}
                                </>
                              )})}
                            </>
                            )
                        })}  
                          </tr>                      
                        </tbody>
                      }
                      </>                    
                    )
                    })}                
                <tbody>
                                     
                  </tbody>
              </table>
        
              </div>
              
              

             
              </div>

            </div>
            
              </>
              }


                </div>              
              </div>
              </div>
        </section>
      </div>
    </>
  );
};

export default Home;
