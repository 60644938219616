import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
import iprismlogo from "../Header/logo-white.png";
import useToken from "../App/useToken";
import LoginModal from "../Login/LoginModal";
import Error from "../FormElements/Error";

import Navigation from "../Navigation/Navigation";
import UserInfo from "../Header/UserInfo";
import Logos from "../Header/Logos";
import Notifications from "../Header/Notifications";

const refreshPage = () => {
  console.log("REFRESHING PAGE");
  window.location.reload(false);
};

const logout = () => {
  console.log("LOGGING OUT");
  localStorage.removeItem("token");
  localStorage.removeItem("access_token");
  console.log("LOGGED OUT");
  refreshPage();
};
const Header = (props) => {
  const { token, setToken } = useToken();
  const [userinfo, setUserInfo] = useState([]);
  const [userfullname, setUserFullname] = useState("");
  const [useremail, setUserEmail] = useState("");
  const [useragentref, setAgentRef] = useState("");
  const [useragentname, setAgentName] = useState("");

  //On Screen Messages
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorcode, setErrorCode] = useState(0);
  const [loginmodal, setLoginModal] = useState(false);

  useEffect(() => {
    (async () => {
      const apidata = JSON.stringify({});

      let code = 0;
      try {
        const response = await fetch("//devwebapi.iprism.co.uk/users/me", {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const jsonResponse = await response.json();
          const listFromJson = jsonResponse;
          setUserFullname((prev) => {
            return [listFromJson.fullname];
          });
          setUserEmail((prev) => {
            return [listFromJson.email];
          });
          setAgentRef((prev) => {
            return [listFromJson.agentref];
          });
          setAgentName((prev) => {
            return [listFromJson.agentname];
          });
          setLoading(false);
          setLoaded(true);
          return listFromJson;
        } else {
          console.log(response);
          code = response.status;
          console.log("THIS IS THE SECTION");
          throw new Error(response.status);
        }
      } catch (error) {
        if (code === 401) {
          setLoginModal(true);
        } else {
          setError(true);
          setErrorCode(code);
        }
      }
    })();
  }, []);

  const refreshPage = () => {
    console.log("REFRESHING PAGE");
    window.location.reload(false);
  };

  const logout = () => {
    console.log("LOGGING OUT");
    localStorage.removeItem("token");
    console.log("LOGGED OUT");
    refreshPage();
  };

  const handleRightSidebar = () => {
    alert("THIS SHOULD OPEN THE SIDEBAR");
  };

  return (
    <>
      {loginmodal === true && <LoginModal setToken={setToken} />}

      {error === true && (
        <Error
          code={errorcode}
          endpoint="WebsiteContentList"
          component="announcement"
        />
      )}

      {error === false && (
        <>
          <header className="header header-nav-menu bg-primary border-0 mt-0 pt-0 p-0 m-0 mb-4 mb-md-5 ">
            <div className="logo-container bg-primary border-0">
              <Logos />
              <button
                className="btn header-btn-collapse-nav d-lg-none"
                data-bs-toggle="collapse"
                data-bs-target=".header-nav"
              >
                <i className="fas fa-bars text-secondary"></i>
              </button>

              <div className="d-lg-none float-end pt-md-2">                
              </div>
              <div className="header-nav collapse">
                <div className="header-nav-main header-nav-main-effect-1 header-nav-main-sub-effect-1 header-nav-main-square">
                  <Navigation
                    current={props.current}
                    dropdown={props.dropdown}
                  />
                </div>
              </div>
            </div>
            <div className="header-right mb-0 mt-1 mt-md-1">              
              <div className="d-none d-lg-block pt-md-2">                              
                <span className="separator"></span>
                <UserInfo />
              </div>
              <div className="d-md-none bg-primary mb-0 mt-1">
                <h3 className="text-light p-3 pt-5 text-4">
                  {props.current}
                  <span className="float-end text-light d-none">
                    <div className="d-block d-sm-none">XS</div>
                    <div className="d-none d-sm-block d-md-none">SM</div>
                    <div className="d-none d-md-block d-lg-none">MD</div>
                    <div className="d-none d-lg-block d-xl-none">LG</div>
                    <div className="d-none d-xl-block">XL</div>
                  </span>
                </h3>
              </div>
            </div>
          </header>
        </>
      )}
    </>
  );
};

export default Header;
