// ./components/FormInput.jsx
import { useState } from "react";
import Moment from "react-moment";

const ReadOnly = (props) => {
  const [inputType] = useState(props.type);
  const [inputValue, setInputValue] = useState("");

  return (
    <>
      <div className="row text-2">
        <div className="col-6 font-weight-bold text-primary pt-1 pb-1">
          <span>{props?.title ? props.title : ""}</span>
        </div>
        {props?.extrainfo !== undefined && (
          <>
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 pt-1 pb-1">
              {props?.type === "dateshort" && props.value !== null && (
                <Moment format="DD/MM/YYYY">{props.value}</Moment>
              )}
              {props?.type === "datelong" && props.value !== null && (
                <Moment format="DD/MM/YYYY LTS">{props.value}</Moment>
              )}
              {props?.type !== "datelong" && props?.type !== "dateshort" && (
                <>{props.value}</>
              )}
            </div>
          </>
        )}
        {props?.extrainfo === undefined && (
          <div className="col-6 pt-1 pb-1">
            {props?.type === "dateshort" && props.value !== null && (
              <Moment format="DD/MM/YYYY">{props.value}</Moment>
            )}
            {props?.type === "datelong" && props.value !== null && (
              <Moment format="DD/MM/YYYY LTS">{props.value}</Moment>
            )}
            {props?.type !== "datelong" && props?.type !== "dateshort" && (
              <>{props.value}</>
            )}
          </div>
        )}
        {/*<div className="col-1 order-2 order-md-3">H</div>*/}
      </div>
    </>
  );
};

export default ReadOnly;
